<template>
  <el-dialog
    :model-value='modelValue'
    width='60%'
    title='用户收藏'
    @close='closed'
  >
    <el-table :data="tableDataList" style="width: 100%">
      <el-table-column prop="product.title" label="产品名称" min-width='200'/>
      <el-table-column property="product.categoryId" label="产品分类" min-width="100">
        <template #default="scope">
          <template v-for="item in categoryListData">
            <template v-if="item.id == scope.row.product.categoryId">
              {{ item.name }}
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column property="product.status" label="产品状态" min-width="80">
        <template #default="scope">
          <span v-if="scope.row.product.status == 1">在售</span>
          <span v-else-if="scope.row.product.status == 2">已售完</span>
          <span v-else-if="scope.row.product.status == 3">已下架</span>
        </template>
      </el-table-column>
      <el-table-column property="product.term" label="最高收益" min-width="80">
        <template #default="scope"> {{ scope.row.product.income }}% </template>
      </el-table-column>
      <el-table-column property="product.term" label="产品期限" min-width="80">
        <template #default="scope"> {{ scope.row.product.term }}个月 </template>
      </el-table-column>
      <el-table-column prop="createTime" label="收藏时间" min-width="150" />
    </el-table>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getProductCategoryList } from '@/api/product-category'
import { getUserFavoritesPage } from '@/api/user-favorites'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const loading = ref(false)

/**
 * 产品分类数据
 */
const categoryListData = ref([])
// 获取数据的方法
const getCategoryListData = async () => {
  loading.value = true
  categoryListData.value = await getProductCategoryList()
  loading.value = false
}
getCategoryListData()

/**
 * 获取列表
 * @type {ToRef<*[]>}
 */
const tableDataList = ref([])
const getFavoritesList = async () => {
  loading.value = true
  tableDataList.value = await getUserFavoritesPage(props.id)
  loading.value = false
}
getFavoritesList()

</script>

<style lang='scss' scoped></style>
