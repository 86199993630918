import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/sys/user',
  name: 'sysUser',
  meta: {
    title: 'system',
    icon: 'system'
  },
  children: [
    {
      path: '/sys/user',
      component: () =>
        import(/* webpackChunkName: "sys-user" */ '@/views/sys-user/index'),
      meta: {
        title: 'sysUser',
        icon: 'personnel-manage'
      }
    }
  ]
}
