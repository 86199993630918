import request from '../utils/request'

/**
 * 获取全部角色列表
 * @param data
 * @returns {AxiosPromise}
 */
export const getSysRoleList = () => {
  return request({
    url: '/sysRole'
  })
}

/**
 * 获取角色分页数据
 * @param data
 * @returns {AxiosPromise}
 */
export const getSysRolePage = data => {
  return request({
    url: '/sysRole/page',
    params: data
  })
}

/**
 * 获取指定id角色数据
 * @param roleId
 * @returns {AxiosPromise}
 */
export const getSysRoleByRoleId = roleId => {
  return request({
    url: `/sysRole/${roleId}`
  })
}

/**
 * 添加角色
 * @param data
 * @returns {AxiosPromise}
 */
export const addSysRole = data => {
  return request({
    url: '/sysRole',
    method: 'POST',
    data
  })
}

/**
 * 修改角色
 * @param data
 * @returns {AxiosPromise}
 */
export const updateSysRole = data => {
  return request({
    url: '/sysRole',
    method: 'PUT',
    data
  })
}

/**
 * 删除角色
 * @param roleId
 * @returns {AxiosPromise}
 */
export const deleteSysRoleByRoleId = roleId => {
  return request({
    url: `/sysRole/${roleId}`,
    method: 'DELETE'
  })
}

/**
 * 批量删除角色
 * @param data
 * @returns {AxiosPromise}
 */
export const deleteBatchSysRole = data => {
  return request({
    url: '/sysRole/deleteBatch',
    method: 'DELETE',
    data
  })
}

/**
 * 设置角色的菜单
 * @param roleId
 * @param menuIds
 * @returns {AxiosPromise}
 */
export const setRoleMenu = (roleId, menuIds) => {
  return request({
    url: `/sysRole/setRoleMenu/${roleId}`,
    method: 'POST',
    data: menuIds
  })
}
/**
 * 获取角色的菜单
 * @param roleId
 * @returns {AxiosPromise}
 */
export const getRoleMenu = roleId => {
  return request({
    url: `/sysRole/getRoleMenu/${roleId}`
  })
}
