import layout from '@/layout'

export default {
  path: '/product',
  component: layout,
  redirect: '/product/attr',
  name: 'productAttr',
  meta: {
    title: 'product',
    icon: 'product'
  },
  children: [
    {
      path: '/product/attr',
      component: () =>
        import(
          /* webpackChunkName: "product-attr" */ '@/views/product-attr/index'
        ),
      meta: {
        title: 'productAttr',
        icon: 'personnel-manage'
      }
    }
  ]
}
