import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/sys/user',
  name: 'sysRole',
  meta: {
    title: 'system',
    icon: 'system'
  },
  children: [
    {
      path: '/sys/role',
      component: () =>
        import(/* webpackChunkName: "sys-role" */ '@/views/sys-role/index'),
      meta: {
        title: 'sysRole',
        icon: 'role'
      }
    }
  ]
}
