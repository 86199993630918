import layout from '@/layout'

export default {
  path: '/operateData',
  component: layout,
  redirect: '/operateData/operateChart',
  name: 'operateChart',
  meta: {
    title: 'operateData',
    icon: 'data'
  },
  children: [
    {
      path: '/operateData/operateChart',
      component: () =>
        import(
          /* webpackChunkName: "operate-data" */ '@/views/operate-data/index'
        ),
      meta: {
        title: 'operateChart',
        icon: 'chart'
      }
    }
  ]
}
