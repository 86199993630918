import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import store from '@/store'
import layout from '@/layout'
import RoleListRouter from './modules/RoleList'
import PermissionListRouter from './modules/PermissionList'
import ArticleRouter from './modules/Article'
import ArticleCreaterRouter from './modules/ArticleCreate'
import SysUser from './modules/SysUser'
import SysRole from './modules/SysRole'
import SysMenu from './modules/SysMenu'
import SysLoginRecord from './modules/SysLoginRecord'
import SysOperationRecord from './modules/SysOperationRecord'
import SysDictionary from './modules/SysDictionary'
import ProductCategory from './modules/ProductCategory'
import ProductManage from './modules/ProductManage'
import ProductAttr from './modules/ProductAttr'
import UserManage from './modules/UserManage'
import UserReserve from './modules/UserReserve'
import UserOrder from './modules/UserOrder'
import AppSetting from './modules/AppSetting'
import OperateData from './modules/OperateData'

/**
 * 私有路由表
 */
export const privateRoutes = [
  RoleListRouter,
  PermissionListRouter,
  ArticleRouter,
  ArticleCreaterRouter,
  SysUser,
  SysRole,
  SysMenu,
  SysLoginRecord,
  SysOperationRecord,
  SysDictionary,
  ProductCategory,
  ProductManage,
  ProductAttr,
  UserManage,
  UserReserve,
  UserOrder,
  AppSetting,
  OperateData
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/profile',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/index'),
        meta: {
          title: 'profile',
          icon: 'dashboard'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHashHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

export default router
