import request from '@/utils/request'

/**
 * 获取产品分页
 * @param data
 * @returns {*}
 */
export const getProductPage = data => {
  return request({
    url: '/product/page',
    method: 'GET',
    params: data
  })
}

/**
 * 根据id获取产品
 * @param id
 * @returns {*}
 */
export const getProductById = id => {
  return request({
    url: `/product/${id}`
  })
}

/**
 * 添加产品信息
 */
export const addProduct = data => {
  return request({
    url: '/product',
    method: 'POST',
    data
  })
}

/**
 * 修改产品信息
 */
export const updateProduct = data => {
  return request({
    url: '/product',
    method: 'PUT',
    data
  })
}

/**
 * 根据id删除产品
 * @param id
 * @returns {*}
 */
export const deleteProductById = id => {
  return request({
    url: `/product/${id}`,
    method: 'DELETE'
  })
}

/**
 * 获取上传图片地址
 * @returns {string}
 */
export const getProductImgUploadUrl = () => {
  return process.env.VUE_APP_BASE_API + '/product/upload/img'
}
