import layout from '@/layout'

export default {
  path: '/order',
  component: layout,
  redirect: '/userOrder/manage',
  name: 'userOrder',
  meta: {
    title: 'order',
    icon: 'order'
  },
  children: [
    {
      path: '/userOrder/manage',
      component: () =>
        import(
          /* webpackChunkName: "user-order-manage" */ '@/views/user-order-manage/index'
        ),
      meta: {
        title: 'userOrder',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/userOrder/manage/:userId',
      component: () =>
        import(
          /* webpackChunkName: "user-order-manage" */ '@/views/user-order-manage/index'
        ),
      meta: {
        title: 'userOrderById'
      }
    }
  ]
}
