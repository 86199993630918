import layout from '@/layout'

export default {
  path: '/app',
  component: layout,
  redirect: '/app/manage',
  name: 'appSetting',
  meta: {
    title: 'app',
    icon: 'example'
  },
  children: [
    {
      path: '/app/setting',
      component: () =>
        import(/* webpackChunkName: "app-setting" */ '@/views/app-setting/index'),
      meta: {
        title: 'appSetting',
        icon: 'personnel-manage'
      }
    }
  ]
}
