<template>
  <el-dialog
    :model-value="modelValue"
    width="700px"
    title="添加系统用户"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :inline="true" :label-width="100">
      <el-form-item label="登录账号" prop="username">
        <el-input
          v-model="form.username"
          placeholder="请输入登录账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="phone">
        <div style="width: 202px!important;">
          <el-select v-model="selectRoleIds" multiple placeholder="请选择角色">
            <el-option
              v-for="item in roles"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="addSysUserClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import { getSysRoleList } from '@/api/sys-role'
import { addSysUser } from '@/api/sys-user'

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  username: '',
  nickname: '',
  phone: '',
  roles: []
})
const selectRoleIds = ref([])
const loading = ref(false)
/**
 * 添加的用户角色侦听器，用于将id数组转换为对象
 * @type {ToRef<*[]>}
 */
watch(selectRoleIds, (newRoleIds, oldRoleIds) => {
  form.value.roles = []
  for (const roleId of newRoleIds) {
    form.value.roles.push({ roleId })
  }
})

// 查询所有角色
const roles = ref([])
const getRolesData = async () => {
  loading.value = true
  roles.value = await getSysRoleList()
  loading.value = false
}
getRolesData()

// 添加
const addSysUserClick = async () => {
  loading.value = true
  const pwd = await addSysUser(form.value)
  ElMessageBox.alert(`初始密码为：${pwd}`, '添加成功', {
    confirmButtonText: '确定',
    type: 'success',
    callback: action => {}
  })
  loading.value = false
  closed()
}
</script>

<style lang="scss" scoped></style>
