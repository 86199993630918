import request from '@/utils/request'

/**
 * 查看敏感数据-系统用户手机号
 */
export const getSensitiveSysUserPhone = (userId) => {
  return request({ url: `/sensitive/getSysUserPhone/${userId}` })
}

/**
 * 查看敏感数据-指定用户手机号
 */
export const getSensitiveUserPhone = (userId) => {
  return request({ url: `/sensitive/getUserPhone/${userId}` })
}

/**
 * 查看敏感数据-指定订单手机号
 */
export const getSensitiveOrderPhone = (orderId) => {
  return request({ url: `/sensitive/getOrderPhone/${orderId}` })
}

/**
 * 查看敏感数据-指定预约手机号
 */
export const getSensitiveUserReservePhone = (reserveId) => {
  return request({ url: `/sensitive/getUserReservePhone/${reserveId}` })
}
