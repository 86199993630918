<template>
  <el-dialog
    :model-value='modelValue'
    width='60%'
    title='添加用户'
    @close='closed'
  >
    <el-form ref='formRef' :model='form' :rules='rules' :inline='true' :label-width='100'>
      <el-form-item label='用户类型' prop='userType'>
        <div style='width: 202px!important;'>
          <el-select v-model='form.userType' placeholder='请选择用户类型'>
            <el-option
              v-for='item in userType'
              :key='item.value'
              :label='item.label'
              :value='item.value'
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label='用户来源' prop='userSource'>
        <div style='width: 202px!important;'>
          <el-select v-model='form.userSource' placeholder='请选择用户来源'>
            <el-option
              v-for='item in userSource'
              :key='item.value'
              :label='item.label'
              :value='item.value'
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label='意向度' prop='userRating'>
        <div style='margin-top: 10px;'>
          <el-rate v-model='form.userRating' allow-half />
        </div>
      </el-form-item>
      <el-form-item label='手机号' prop='phone'>
        <el-input
          v-model='form.phone'
          @blur='phoneHandeBlur'
          placeholder='请输入手机号'
        ></el-input>
      </el-form-item>
      <el-form-item label='归属地' prop='phoneAddress'>
        <el-input
          v-model='form.phoneAddress'
          placeholder='请输入归属地'
        ></el-input>
      </el-form-item>
      <el-form-item label='姓名' prop='fullname'>
        <el-input v-model='form.fullname' placeholder='请输入姓名'></el-input>
      </el-form-item>
      <el-form-item label='身份证' prop='idNumber'>
        <el-input
          v-model='form.idNumber'
          @blur='idCardHandeBlur'
          placeholder='请输入身份证'
        ></el-input>
      </el-form-item>
      <el-form-item label='身份证地址' prop='idAddress'>
        <el-input
          v-model='form.idAddress'
          placeholder='请输入身份证地址'
        ></el-input>
      </el-form-item>
      <el-form-item label='性别' prop='sex'>
        <div style='width: 202px!important;'>
          <el-radio-group v-model='form.sex'>
            <el-radio :label='1'>男</el-radio>
            <el-radio :label='2'>女</el-radio>
            <el-radio :label='3'>保密</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='生日' prop='birthday'>
        <div style='width: 202px!important;'>
          <el-date-picker
            v-model='form.birthday'
            type='date'
            placeholder='请选择生日'
            value-format='YYYY-MM-DD'
            style='width: 202px!important;'
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label='邮箱' prop='email'>
        <el-input v-model='form.email' placeholder='请输入邮箱'></el-input>
      </el-form-item>
      <el-form-item label='QQ' prop='qq'>
        <el-input v-model='form.qq' placeholder='请输入QQ'></el-input>
      </el-form-item>
      <el-form-item label='微信' prop='wechat'>
        <el-input v-model='form.wechat' placeholder='请输入微信'></el-input>
      </el-form-item>
      <el-form-item label='是否加微信' prop='isAddWechat'>
        <div style='width: 202px!important;'>
          <el-radio-group v-model='form.isAddWechat'>
            <el-radio :label='false'>未添加</el-radio>
            <el-radio :label='true'>已添加</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='addUserClick'>保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { addUser } from '@/api/user'
import { getPhoneLocal, getIdCardInfo } from '@/api/common'

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  userType: null,
  userSource: null,
  userRating: 3,
  phone: '',
  phoneAddress: '',
  fullname: '',
  idNumber: '',
  sex: 3,
  birthday: '',
  email: '',
  qq: '',
  wechat: '',
  isAddWechat: false,
  status: 1
})
const rules = reactive({
  phone: [
    {
      required: true,
      pattern: /^1\d{10}$/,
      message: '手机号输入不正确',
      trigger: 'blur'
    }]
})
// 用户来源
const userSource = [
  {
    label: '注册',
    value: 1
  },
  {
    label: '后台',
    value: 2
  },
  {
    label: '其他',
    value: 3
  }
]
// 用户类型
const userType = [
  {
    label: '直客',
    value: 1
  },
  {
    label: '渠道客户',
    value: 2
  },
  {
    label: '理财经理',
    value: 3
  },
  {
    label: '项目方',
    value: 4
  },
  {
    label: '其他',
    value: 5
  }
]
const loading = ref(false)

/**
 * 添加
 * @returns {Promise<void>}
 */
const addUserClick = async () => {
  loading.value = true
  await addUser(form.value)
  ElMessage({
    type: 'success',
    message: '添加成功'
  })
  loading.value = false
  closed()
}

/**
 * phone输入框市区失去焦点事件
 */
const phoneHandeBlur = async e => {
  form.value.phone = form.value.phone.trim()
  if (form.value.phone.length !== 11) {
    return
  }
  // 查询归属地
  const result = await getPhoneLocal(form.value.phone)
  form.value.phoneAddress = result.mobileprovice + '-' + result.mobilearea
}

/**
 * 身份证输入框市区失去焦点事件
 */
const idCardHandeBlur = async e => {
  form.value.idNumber = form.value.idNumber.trim()
  if (form.value.idNumber.length !== 15 && form.value.idNumber.length !== 18) {
    return
  }
  // 查询归属地
  const result = await getIdCardInfo(form.value.idNumber)
  if (result.sex === '男') {
    form.value.sex = 1
  } else if (result.sex === '女') {
    form.value.sex = 2
  } else {
    form.value.sex = 3
  }
  form.value.idAddress = result.address
  form.value.birthday = result.birthday
}
</script>

<style lang='scss' scoped></style>
