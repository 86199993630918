<template>
  <el-dialog
    :model-value="modelValue"
    :close-on-click-modal="false"
    width="50%"
    title="处理预约"
    @close="closed"
  >
    <el-form :model="form">
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" rows="3"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">提交处理</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { updateUserReserve } from '@/api/user-reserve'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const form = ref({
  id: props.id,
  remark: '',
  status: 1
})

const onSubmitClick = async () => {
  await updateUserReserve(form.value)
  ElMessage.success('处理成功')
  closed()
}
</script>

<style lang="scss" scoped></style>
