<template>
  <el-dialog
    :model-value='modelValue'
    width='70%'
    :title="props.orderId === 0?'添加用户订单':'修改用户订单'"
    :close-on-click-modal='false'
    @close='closed'
  >
    <el-tabs v-model='activeName' @tab-click='tabsHandleClick' v-loading='loading'>
      <el-tab-pane label='产品订单信息' name='info'>
        <el-form ref='formRef' :model='form' :rules='rules' :inline='true' :label-width='100'>
          <el-row class='order-row'>
            <el-col :span='8'>
              <el-form-item label='受益人' prop='fullname'>
                <el-input
                  v-model='form.fullname'
                  placeholder='请输入受益人姓名'

                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='身份证号' prop='idNumber'>
                <el-input
                  v-model='form.idNumber'
                  placeholder='请输入身份证号'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='联系电话' prop='phone'>
                <el-input
                  v-if='props.orderId === 0 || (form.phone!=undefined && form.phone.trim().length === 0)'
                  v-model='form.phone'
                  placeholder='请输入联系电话(手机号)'
                ></el-input>
                <div v-else>
                  <span
                    @click="showOrderPhoneNumber(form)"
                    v-if="form.showPhoneNumber != true"
                  >{{ phoneFormat(form.phone) }}</span
                  >
                  <span v-else>{{ form.phone }}</span>
                  <el-link style='margin-left: 5px;' type="primary" @click='setPhoneClick'>修改</el-link>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span='24'>
              <el-form-item label='邮寄地址' style='width: 100%'>
                <el-input
                  v-model='form.address'
                  :rows='2'
                  type='textarea'
                  placeholder='请输入合同邮寄地址'
                />
              </el-form-item>

            </el-col>
            <el-divider style='margin-top: 10px;'>
            </el-divider>
            <el-col :span='8'>
              <el-form-item label='产品名称' prop='productId'>
                <el-select
                  v-model='form.productId'
                  filterable
                  remote
                  reserve-keyword
                  placeholder='请输入项目名称'
                  :remote-method='productRemoteMethod'
                  :loading='loading'
                  style='width: 100%'
                >
                  <el-option
                    v-for='item in productOptions'
                    :key='item.id'
                    :label='item.title'
                    :value='item.id'
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='投资金额' prop='buyMoney'>
                <el-input
                  v-model.number='form.buyMoney'
                  placeholder='请输入金额'
                >
                  <template #append>万</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='投资期限' prop='buyTerm'>
                <el-input
                  v-model.number='form.buyTerm'
                  placeholder='请输入月数'
                >
                  <template #append>月</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='付息方式' prop='payInterestType'>
                <el-select
                  v-model='form.payInterestType'
                  clearable
                  placeholder='请选择付息方式'
                  style='width: 100%'
                >
                  <el-option label='每月付息' :value='1'></el-option>
                  <el-option label='每3个月付息' :value='2'></el-option>
                  <el-option label='每6个月付息' :value='3'></el-option>
                  <el-option label='每年付息' :value='4'></el-option>
                  <el-option label='按月付息' :value='5'></el-option>
                  <el-option label='自然季度付息' :value='6'></el-option>
                  <el-option label='自然半年付息' :value='7'></el-option>
                  <el-option label='按年付息' :value='8'></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='年化收益率' prop='yield'>
                <el-input
                  v-model='form.yield'
                  placeholder='请输入收益率'
                >
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='纳税类型' prop='taxType'>
                <el-radio-group v-model='form.taxType'>
                  <el-radio :label='1'>税前</el-radio>
                  <el-radio :label='2'>税后</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='打款时间' prop='paymentTime'>
                <el-date-picker style='width: 100%' v-model='form.paymentTime' type='date' placeholder='请选择打款时间'
                                value-format='YYYY-MM-DD'
                                @change='paymentTimeChange'>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='成立时间' prop='foundedTime'>
                <el-date-picker style='width: 100%' v-model='form.foundedTime' type='date' placeholder='请选择成立时间'
                                value-format='YYYY-MM-DD'>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='到期时间' prop='expireTime'>
                <el-date-picker style='width: 100%' v-model='form.expireTime' type='date' placeholder='请选择到期时间'
                                value-format='YYYY-MM-DD'
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='打款银行' prop='paymentBank'>
                <el-input
                  v-model='form.paymentBank'
                  placeholder='请输入银行名称'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='打款卡号' prop='paymentBankNumber'>
                <el-input
                  v-model='form.paymentBankNumber'
                  placeholder='请输入银行卡号'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='银行行号' prop='bankNumber'>
                <el-input
                  v-model='form.bankNumber'
                  placeholder='请输入银行行号'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='收益补贴' prop='incomeSubsidy'>
                <el-input
                  v-model='form.incomeSubsidy'
                  placeholder='请输入收益补贴内容'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='赠送礼物'>
                <el-input
                  v-model='form.giveGift'
                  placeholder='请输入赠送礼物内容'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='介绍人'>
                <el-input
                  v-model='form.introducer'
                  placeholder='请输入介绍人'
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='24'>
              <el-form-item label='介绍赠礼'>
                <el-input
                  v-model='form.introducerGift'
                  placeholder='请输入介绍赠礼内容'
                  :maxlength="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='24'>
              <el-form-item label='备注' style='width: 100%'>
                <el-input
                  v-model='form.remarks'
                  :rows='2'
                  type='textarea'
                  placeholder='请输入备注内容'
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label='预计付息时间' name='time'>
        <div style='text-align: center;margin-bottom: 20px;'>
          <el-button-group>
            <el-button type='primary' @click='makeTimeClick'>生成付息计划</el-button>
            <el-button type='primary' @click='makeMoneyClick'>生成付息金额</el-button>
          </el-button-group>
          <el-button-group style='margin-left: 10px;'>
            <el-button type='primary' @click='addMakeRowClick'>添加行</el-button>
            <el-button type='primary' @click='clearMakeRowClick'>清空行</el-button>
          </el-button-group>
        </div>
        <el-form ref='dynamicValidateForm'
                 :label-width='100' size='mini'>
          <el-row class='order-row' v-for='(item, index) in interestPayment' :key='index'>
            <el-col :span='8'>
              <el-form-item label='付息时间'>
                <el-input v-model='item.time'></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='付息金额'>
                <el-input v-model='item.money' @input='makeMoneyInput'></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-button @click.prevent='removeMakeRowClick(item)' style='margin-left: 20px;'>删除</el-button>
            </el-col>
          </el-row>
        </el-form>
        <div style='text-align: center;font-size: 16px;'>付息合计金额：<span style='color: #ff4d4f;'>{{ interestCount.toFixed(2) }}</span> </div>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='addOrUpdateClick'>保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, reactive, ref } from 'vue'
import { getProductPage } from '@/api/product'
import { addUserOrder, updateUserOrder, getUserOrderById } from '@/api/user-order'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { ElMessage } from 'element-plus'
import { getSensitiveOrderPhone } from '../../../api/sensitive'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  userId: {
    type: Number,
    required: true
  },
  orderId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'userId', 'orderId', 'close'])

const activeName = ref('info')
const tabsHandleClick = (tab, event) => {
}

const formRef = ref(null)
const form = ref({
  userId: props.userId,
  userOrderIncomes: []
})

const rules = ref({
  fullname: [
    {
      required: true,
      message: '受益人不能为空',
      trigger: 'blur'
    }
  ],
  idNumber: [
    {
      required: true,
      pattern: /\d{17}[\d|x]|\d{15}/,
      message: '身份证号不正确',
      trigger: 'blur'
    }
  ],
  phone: [
    {
      required: true,
      pattern: /^1.{10}$/,
      message: '联系电话输入不正确',
      trigger: 'blur'
    }
  ],
  productId: [
    {
      required: true,
      message: '产品名称不能为空',
      trigger: 'blur'
    }
  ],
  buyMoney: [
    {
      required: true,
      message: '投资金额不能为空',
      trigger: 'blur'
    }
  ],
  buyTerm: [
    {
      required: true,
      message: '投资期限不能为空',
      trigger: 'blur'
    }
  ],
  payInterestType: [
    {
      required: true,
      message: '付息方式不能为空',
      trigger: 'change'
    }
  ],
  yield: [
    {
      required: true,
      message: '年化收益率不能为空',
      trigger: 'blur'
    }
  ],
  taxType: [
    {
      required: true,
      message: '纳税类型不能为空',
      trigger: 'blur'
    }
  ],
  paymentTime: [
    {
      required: true,
      message: '打款时间不能为空',
      trigger: 'change'
    }
  ],
  foundedTime: [
    {
      required: true,
      message: '成立时间不能为空',
      trigger: 'change'
    }
  ],
  expireTime: [
    {
      required: true,
      message: '过期时间不能为空',
      trigger: 'change'
    }
  ],
  paymentBank: [
    {
      required: true,
      message: '打款银行不能为空',
      trigger: 'change'
    }
  ],
  bankNumber: [
    {
      required: true,
      message: '银行行号不能为空',
      trigger: 'change'
    }
  ],
  paymentBankNumber: [
    {
      required: true,
      message: '打款卡号不能为空',
      trigger: 'change'
    }
  ],
  incomeSubsidy: [
    {
      required: true,
      message: '收益补贴不能为空',
      trigger: 'change'
    }
  ]
})

const userOrderFiles = ref([])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const loading = ref(false)
/**
 * 付息时间计划
 */
const interestPayment = reactive([])

/**
 * 付息合计
 * @type {Ref<UnwrapRef<number>>}
 */
const interestCount = ref(0.00)

/**
 * 更新付息合计
 */
const updateInterestCount = () => {
  interestCount.value = 0.00
  for (const income of interestPayment) {
    if (income.money !== '' || income.money !== ' ') {
      interestCount.value += parseFloat(income.money)
    }
  }
}

/**
 * 预计付息时间金额变更事件
 */
const makeMoneyInput = () => {
  updateInterestCount()
}

/**
 * 获取用户订单信息
 */
const getOrderInfo = async () => {
  loading.value = true
  form.value = await getUserOrderById(props.orderId)
  form.value.buyMoney = form.value.buyMoney / 10000
  for (const income of form.value.userOrderIncomes) {
    interestPayment.push({
      time: dayjs(income.time).format('YYYY-MM-DD'),
      money: income.money
    })
  }
  productOptions.value.push(form.value.product)
  loading.value = false
  updateInterestCount()
}
if (props.orderId > 0) {
  getOrderInfo()
}

/**
 * 产品名称远程搜索
 * @type {ToRef<*[]>}
 */
const productOptions = ref([])
const productRemoteMethod = async (query) => {
  if (query !== '' && query.length > 1) {
    loading.value = true
    const productResult = await getProductPage({ title: query })
    productOptions.value = productResult.list
    loading.value = false
  } else {
    productOptions.value = []
  }
}

/**
 * 打款时间选定事件
 */
const paymentTimeChange = () => {
  if (form.value.paymentTime == null) {
    return
  }
  // 设置成立时间
  if (form.value.paymentTime != null) {
    form.value.foundedTime = dayjs(form.value.paymentTime).format('YYYY-MM-DD')
  }
  // 设置生成到期时间
  if (Number.isInteger(form.value.buyTerm)) {
    form.value.expireTime = dayjs(form.value.paymentTime).add(form.value.buyTerm, 'month').format('YYYY-MM-DD')
  }
}

/**
 * 生成付息计划时间
 */
const makeTimeClick = () => {
  // 必填信息表单验证
  formRef.value.validate((valid) => {
    if (!valid) {
      // ElMessage.error('产品订单信息必填项未填写完整')
      // return
    }
    clearMakeRowClick()
    const foundedDate = dayjs(form.value.foundedTime) // 成立日期
    let monthInterval = 0 // 月间隔
    let rowNum = 0 // 行数
    if (form.value.payInterestType <= 4) {
      // 根据成立日期当天时间计算
      switch (form.value.payInterestType) {
        case 1:
          monthInterval = 1
          break
        case 2:
          monthInterval = 3
          break
        case 3:
          monthInterval = 6
          break
        case 4:
          monthInterval = 12
          break
      }
      rowNum = Math.ceil(form.value.buyTerm / monthInterval)
      for (let i = 1; i <= rowNum; i++) {
        interestPayment.push({
          time: foundedDate.add(monthInterval * i, 'month').format('YYYY-MM-DD'),
          money: ''
        })
      }
    } else {
      // 跟进自然日期固定时间计算
      let settlementDate = dayjs(foundedDate)
      dayjs.extend(quarterOfYear)
      // 当前成立日期所属季度
      const quarter = foundedDate.quarter()
      switch (form.value.payInterestType) {
        case 5:
          monthInterval = 1 // 按月付息
          // 判断是否超过本月10号
          if (foundedDate.date() >= 10) {
            // 设置为下月10号
            settlementDate = settlementDate.add(1, 'month').date(10)
          } else {
            settlementDate = settlementDate.date(10)
          }
          break
        case 6:
          monthInterval = 3 // 自然季度付息
          // 计算当前季度的结算时间（季度最后一个月的10号）
          var quarterPayTime = dayjs(foundedDate.year() + '-' + (3 * quarter) + '-' + 10)
          // 判断成立时间是否已经超过当前季度的结算时间，或相等
          if (foundedDate.isAfter(quarterPayTime) || foundedDate.isSame(quarterPayTime)) {
            // 进入下一季度结算，加上3个月
            quarterPayTime = quarterPayTime.add(3, 'month')
          }
          settlementDate = quarterPayTime
          break
        case 7:
          monthInterval = 6 // 自然半年付息
          // 计算当前半年的结算时间（半年最后一个月的10号）
          var halfYearTime = dayjs(foundedDate.year() + '-6-' + 10)
          if (foundedDate.isAfter(halfYearTime) || foundedDate.isSame(halfYearTime)) {
            halfYearTime = dayjs(foundedDate.year() + '-12-' + 10)
          }
          settlementDate = halfYearTime
          break
        case 8:
          monthInterval = 12 // 按年付息
          // 计算当前年的结算时间（今年最后一个月的10号）
          var yearTime = dayjs(foundedDate.year() + '-12-' + 10)
          if (foundedDate.isAfter(yearTime) || foundedDate.isSame(yearTime)) {
            // 进入下一年结算
            yearTime = yearTime.add(1, 'year')
          }
          settlementDate = yearTime
          break
      }
      rowNum = Math.ceil(form.value.buyTerm / monthInterval) + 1
      for (let i = 0; i <= rowNum; i++) {
        const date = settlementDate.add(monthInterval * i, 'month')
        // 判断如果超出到期日，则到期日结算
        if (date.isAfter(form.value.expireTime)) {
          interestPayment.push({
            time: dayjs(form.value.expireTime).format('YYYY-MM-DD'),
            money: ''
          })
          return
        }
        interestPayment.push({
          time: date.format('YYYY-MM-DD'),
          money: ''
        })
      }
    }
  })
}

/**
 * 生成付息机计划金额
 */
const makeMoneyClick = () => {
  // 全部利息，(投资金额 * 年化利率)
  const allInterest = form.value.buyMoney * (form.value.yield / 100)
  console.log('全部利息：' + allInterest)
  // 每天利息 (全部利息 / 365)
  const dayInterest = allInterest / 365
  console.log('每天利息：' + dayInterest)
  for (let i = 0; i < interestPayment.length; i++) {
    let interest = 0
    if (i === 0) {
      // 首次付息日，计算成立时间 -> 第一次付息日的天数 * 每天利息
      interest = dayjs(interestPayment[i].time).diff(dayjs(form.value.foundedTime), 'day') * dayInterest
    } else if (i === interestPayment.length - 1) {
      // 最后到期日，计算上次付息日 -> 最后到期日的天数 * 每天利息 + 本金 + 当前利息
      interest = dayjs(form.value.expireTime).diff(interestPayment[i - 1].time, 'day') * dayInterest + form.value.buyMoney + dayInterest
    } else {
      // 正常付息日，上次付息日 -> 本次付息日的天数 * 每天利息
      interest = dayjs(interestPayment[i].time).diff(interestPayment[i - 1].time, 'day') * dayInterest
    }
    const interestMoeny = Math.floor(interest * 100 * 10000) / 100
    interestPayment[i] = {
      time: interestPayment[i].time,
      money: interestMoeny
    }
    interestCount.value += interestMoeny
  }
  updateInterestCount()
}
/**
 * 添加一行
 */
const addMakeRowClick = () => {
  interestPayment.push({
    time: null,
    money: ''
  })
}
/**
 * 清空所有行
 */
const clearMakeRowClick = () => {
  interestPayment.splice(0, interestPayment.length)
  updateInterestCount()
}
const removeMakeRowClick = (item) => {
  const index = interestPayment.indexOf(item)
  if (index !== -1) {
    interestPayment.splice(index, 1)
  }
  updateInterestCount()
}

/**
 * 添加或更新用户订单
 */
const addOrUpdateClick = () => {
  formRef.value.validate(async valid => {
    if (!valid) {
      return
    }
    loading.value = true
    // 将预计付息时间设置到提交数据中
    form.value.userOrderIncomes = interestPayment
    // 将金额"万"变成"元"
    form.value.buyMoney = form.value.buyMoney * 10000
    if (props.orderId > 0) {
      await updateUserOrder(form.value)
      ElMessage.success('修改成功')
    } else {
      await addUserOrder(form.value)
      ElMessage.success('添加成功')
    }
    loading.value = false
    closed()
  })
}

const setPhoneClick = () => {
  form.value.phone = ''
}

/**
 * 手机号格式化
 * @param phone
 * @returns {string|*}
 */
const phoneFormat = phone => {
  try {
    const start = phone.slice(0, 3)
    const end = phone.slice(-4)
    return start + '****' + end
  } catch (ex) {
    console.log(ex)
  }
  return phone
}

const showOrderPhoneNumber = row => {
  row.showPhoneNumber = true
  // 请求获取订单敏感手机号
  getSensitiveOrderPhone(row.id).then(res => {
    row.phone = res
  })
}
</script>

<style lang='scss' scoped>
.order-row {
  .el-form-item {
    width: 100%;
  }
}
</style>
