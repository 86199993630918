import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/sys/operationRecord',
  name: 'sysOperationRecord',
  meta: {
    title: 'system',
    icon: 'system'
  },
  children: [
    {
      path: '/sys/operationRecord',
      component: () =>
        import(
          /* webpackChunkName: "sys-operation-record" */ '@/views/sys-operation-record/index'
        ),
      meta: {
        title: 'sysOperationRecord',
        icon: 'article'
      }
    }
  ]
}
