import request from '@/utils/request'

/**
 * 获取用户列表数据
 */
export const getSysUserPage = data => {
  return request({
    url: '/sysUser/page',
    params: data
  })
}

/**
 * 获取用户列表数据
 */
export const getSysUserList = () => {
  return request({
    url: '/sysUser/list'
  })
}

/**
 * 获取指定用户数据
 * @param userId
 * @returns {*}
 */
export const getSysUserByUserId = userId => {
  return request({
    url: '/sysUser/' + userId
  })
}

/**
 * 添加系统用户
 * @param data
 * @returns {*}
 */
export const addSysUser = data => {
  return request({
    url: '/sysUser',
    method: 'POST',
    data
  })
}
/**
 * 修改系统用户
 * @param data
 * @returns {*}
 */
export const updateSysUser = data => {
  return request({
    url: '/sysUser',
    method: 'PUT',
    data
  })
}

/**
 * 删除系统用户
 * @param data
 * @returns {*}
 */
export const deleteSysUser = userId => {
  return request({
    url: '/sysUser/' + userId,
    method: 'DELETE'
  })
}

/**
 * 批量删除系统用户
 * @param data
 * @returns {*}
 */
export const deleteBatchSysUser = data => {
  return request({
    url: '/sysUser/deleteBatch',
    method: 'DELETE',
    data
  })
}

/**
 * 重置用户密码
 * @param data
 * @returns {*}
 */
export const resetPassword = data => {
  return request({
    url: '/sysUser/resetPassword',
    method: 'PUT',
    data
  })
}
