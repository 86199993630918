import request from '@/utils/request'

/**
 * 查询指定Id用户收藏列表
 */
export const getUserFavoritesPage = userId => {
  return request({
    url: `/userFavorites/${userId}`
  })
}
