import request from '@/utils/request'

/**
 * 获取全部产品分类
 */
export const getProductCategoryList = () => {
  return request({
    url: '/product/category',
    method: 'GET'
  })
}

/**
 * 获取全部产品分类
 */
export const getProductCategoryById = id => {
  return request({
    url: `/product/category/${id}`,
    method: 'GET'
  })
}

/**
 * 添加产品分类
 */
export const addProductCategory = data => {
  return request({
    url: '/product/category',
    method: 'POST',
    data
  })
}

/**
 * 修改产品分类
 */
export const updateProductCategory = data => {
  return request({
    url: '/product/category',
    method: 'PUT',
    data
  })
}

/**
 * 删除指定id产品分类
 * @param id 产品分类id
 * @returns {*}
 */
export const deleteProductCategoryById = id => {
  return request({
    url: `/product/category/${id}`,
    method: 'DELETE'
  })
}
