<template>
  <el-dialog
    :model-value='modelValue'
    width='400px'
    title='变更登录手机号'
    @close='closed'
  >
    <el-form ref='formRef' label-position='top' :model='form' :rules="rules" :label-width='100'>
      <el-form-item label='用户id' v-if='false'>
        <el-input
          v-model='form.userId'
          readonly
          disabled
          placeholder='请输入手机号'
        ></el-input>
      </el-form-item>
      <el-form-item label='当前手机号'>
        <el-input
          v-model='phone'
          readonly
          disabled
          placeholder='请输入手机号'
        ></el-input>
      </el-form-item>
      <el-form-item label='新手机号'  prop='newPhone'>
        <el-input
          v-model='form.newPhone'
          placeholder='请输入新手机号'
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='setUserLoginPhoneClick'>确认变更</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { setUserLoginPhone, getUserById } from '@/api/user'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const formRef = ref(null)

const rules = reactive({
  newPhone: [
    {
      required: true,
      pattern: /^1\d{10}$/,
      message: '手机号输入不正确',
      trigger: 'blur'
    }]
})

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  userId: props.id,
  newPhone: ''
})

const phone = ref('')

/**
 * 获取要修改的用户信息
 */
const getUser = async () => {
  const userInfo = await getUserById(props.id)
  phone.value = userInfo.phone
}
getUser()

/**
 * 修改
 * @returns {Promise<void>}
 */
const setUserLoginPhoneClick = async () => {
  loading.value = true
  await setUserLoginPhone(form.value.userId, form.value.newPhone)
  ElMessage({
    type: 'success',
    message: '修改成功'
  })
  loading.value = false
  closed()
}

const loading = ref(false)

</script>

<style scoped>

</style>
