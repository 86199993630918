<template>
  <el-dialog
    :model-value='modelValue'
    width='300px'
    title='进度管理'
    @close='closed'
  >
    <el-form ref='formRef' :model='form' :inline='true' :label-width='70' v-loading='loading'>
      <el-form-item label='合同'>
        <el-select
          v-model='form.contractStatus'
          placeholder='请选择'
          style='width: 100%'
        >
          <el-option label='未回收' :value='0'></el-option>
          <el-option label='已回收' :value='1'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='确认书'>
        <el-select
          v-model='form.confirmationStatus'
          placeholder='请选择'
          style='width: 100%'
        >
          <el-option label='未寄出' :value='0'></el-option>
          <el-option label='已寄出' :value='1'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='备注'>
        <el-input
          type='textarea'
          v-model='form.speedRemarks'
          placeholder='请输入备注'
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='updateProgressClick'>保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getUserOrderById, updateUserOrderProgress } from '@/api/user-order'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const formRef = ref(null)
const form = ref({})

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const loading = ref(false)

/**
 * 获取订单信息
 */
const getOrderInfo = async () => {
  loading.value = true
  form.value = await getUserOrderById(props.id)
  loading.value = false
}
getOrderInfo()

/**
 * 提交更新
 */
const updateProgressClick = async () => {
  loading.value = true
  await updateUserOrderProgress(form.value)
  ElMessage.success('修改成功')
  loading.value = false
  closed()
}

</script>

<style lang='scss' scoped></style>
