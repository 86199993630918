import request from '@/utils/request'

/**
 * 用户跟进记录分页查询
 */
export const getUserFollowRecordPage = data => {
  return request({
    url: '/userFollowRecord/page',
    params: data
  })
}

/**
 * 添加用户跟进记录
 */
export const addUserFollowRecord = data => {
  return request({
    url: '/userFollowRecord',
    method: 'POST',
    data
  })
}
