<template>
  <el-dialog
    :model-value='modelValue'
    width="280px"
    title='分配给员工'
    @close='closed'
  >
    <el-select v-model="form.sysUserId" filterable placeholder="请选择员工">
      <el-option label="不分配" :value="0"></el-option>
      <el-option
        v-for="item in sysUsers"
        :key="item.userId"
        :label="item.nickname"
        :value="item.userId">
      </el-option>
    </el-select>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='assignUserClick'>保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getSysUserList } from '@/api/sys-user'
import { assignUser } from '@/api/user'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  ids: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'ids', 'close'])

const formRef = ref(null)
const form = ref({
  sysUserId: '',
  userIds: props.ids
})

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

/**
 * 获取管理员列表
 */
const sysUsers = ref([])
const getSysUsers = async () => {
  sysUsers.value = await getSysUserList()
}

/**
 * 分配点击
 */
const assignUserClick = async () => {
  await assignUser(form.value)
  ElMessage.success('分配成功')
  closed()
}
getSysUsers()

</script>

<style lang='scss' scoped></style>
