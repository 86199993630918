<template>
  <el-dialog
    :model-value='modelValue'
    width='800px'
    title='付息表'
    @close='closed'
  >
    <div id='printMe' v-if='form!=null'>

      <div class='print-info'>
        <div class='row input'>
          <input type='text' v-model='form.product.title'>
        </div>
        <div class='row'>
          <div class='txt'>投资金额</div>
          <div class='txt'>{{ form.buyMoney / 10000 }}万元</div>
        </div>
        <div class='row'>
          <div class='txt'>认购期限</div>
          <div class='txt'>{{ form.buyTerm }}个月</div>
        </div>
        <div class='row'>
          <div class='txt'>合同收益</div>
          <div class='txt'>{{ form.yield }}%/年</div>
        </div>
        <div class='row'>
          <div class='txt'>付息方式</div>
          <div class='txt'>
            <template v-if='form.payInterestType == 1'>每月付息</template>
            <template v-else-if='form.payInterestType == 2'>每3个月付息</template>
            <template v-else-if='form.payInterestType == 3'>每6个月付息</template>
            <template v-else-if='form.payInterestType == 4'>每年付息</template>
            <template v-else-if='form.payInterestType == 5'>按月付息</template>
            <template v-else-if='form.payInterestType == 6'>自然季度付息</template>
            <template v-else-if='form.payInterestType == 7'>自然半年付息</template>
            <template v-else-if='form.payInterestType == 8'>按年付息</template>
          </div>
        </div>
        <div class='row'>
          <div class='txt'>成立时间</div>
          <div class='txt'>{{ form.foundedTime }}</div>
        </div>
        <div class='row'>
          <div class='txt'>到期时间</div>
          <div class='txt'>{{ form.expireTime }}</div>
        </div>
        <div class='row'>
          <div class='txt'>受益人</div>
          <div class='txt'>{{ form.fullname }}</div>
        </div>
        <div class='row'>
          <div class='txt'>开户行</div>
          <div class='txt'>{{ form.paymentBank }}</div>
        </div>
        <div class='row'>
          <div class='txt'>打款银行卡</div>
          <div class='txt'>{{ form.paymentBankNumber }}</div>
        </div>
        <div class='row'>
          <div class='txt head-text'>付息时间</div>
          <div class='txt head-text'>预计付息金额</div>
        </div>
        <template v-for='(item,index) in form.userOrderIncomes' :key='index'>
          <div class='row'>
            <div class='txt'>{{ item.time }}</div>
            <div class='txt'>{{ item.money }}</div>
          </div>
        </template>
        <div class='row input'>
          <textarea v-model='textInfo'>
          </textarea>
        </div>
      </div>

    </div>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' v-print="'#printMe'">打印付息表</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getUserOrderById } from '@/api/user-order'
import { ElMessage } from 'element-plus'
import print from 'vue3-print-nb'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const formRef = ref(null)
const form = ref(null)
const textInfo = ref('')

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const loading = ref(false)

/**
 * 获取订单信息
 */
const getOrderInfo = async () => {
  loading.value = true
  form.value = await getUserOrderById(props.id)
  textInfo.value = '注:付息金额按合同年化收益' + form.value.yield + '%/年计算, 付息时间及到账金额以银行卡实际到账为准,如有疑问请咨询募集工作人员.'
  loading.value = false
}
getOrderInfo()

</script>

<style lang='scss' scoped>
.print-info {
  padding: 40px;
  .row {
    display: flex;
    border: 1px solid #cdcdcd;
    border-right: 0;
    border-bottom: 0;

    .txt {
      padding: 5px;
      width: 50%;
      text-align: center;
      border-right: 1px solid #cdcdcd;
      color: #333333;
    }

    .head-text {
      color: #000000;
      font-weight: bold;
    }
  }

  .input {
    input {
      width: 100%;
      padding: 10px;
      border: 0;
      border-right: 1px solid #cdcdcd;
      font-size: 26px;
      text-align: center;
    }

    textarea {
      width: 100%;
      padding: 10px;
      border: 0;
      font-size: 14px;
      border-right: 1px solid #cdcdcd;
      border-bottom: 1px solid #cdcdcd;
      height: 60px;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    }
  }
}
</style>
