import request from '@/utils/request'

/**
 * 获取时间
 * @returns {*}
 */
export const getTime = () => {
  return request({
    url: '/common/getTime'
  })
}

/**
 * 获取手机号归属地
 */
export const getPhoneLocal = phone => {
  return request({
    url: `/common/getPhoneLocal/${phone}`
  })
}

/**
 * 获取身份证信息
 */
export const getIdCardInfo = idcard => {
  return request({
    url: `/common/getIdCardInfo/${idcard}`
  })
}
