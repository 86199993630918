import request from '@/utils/request'

export const feature = () => {
  return request({
    url: '/user/feature'
  })
}

export const chapter = () => {
  return request({
    url: '/user/chapter'
  })
}

/**
 * 用户分页条件查询
 */
export const getUserPage = data => {
  return request({
    url: '/user/page',
    params: data
  })
}

/**
 * 添加用户
 */
export const addUser = data => {
  return request({
    url: '/user',
    method: 'POST',
    data
  })
}
/**
 * 修改用户
 */
export const updateUser = data => {
  return request({
    url: '/user',
    method: 'PUT',
    data
  })
}

/**
 * 设置用户登录手机号
 */
export const setUserLoginPhone = (userId, newPhone) => {
  return request({
    url: '/user/setUserLoginPhone',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: `userId=${userId}&newPhone=${newPhone}`
  })
}

/**
 * 获取指定Id用户
 * @param userId
 * @returns {*}
 */
export const getUserById = userId => {
  return request({
    url: `/user/${userId}`
  })
}
/**
 * 分配用户
 */
export const assignUser = data => {
  return request({
    url: '/user/assign',
    method: 'POST',
    data
  })
}
