import request from '@/utils/request'

/**
 * 登录
 */
export const login = data => {
  return request({
    url: '/auth/login',
    method: 'POST',
    data
  })
}

/**
 * 注销登陆
 */
export const logout = () => {
  return request({
    url: '/auth/logout',
    method: 'POST'
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/auth/getUserInfo'
  })
}

/**
 * 修改密码
 */
export const setPassword = data => {
  return request({
    url: '/auth/password',
    method: 'PUT',
    data
  })
}
