import request from '@/utils/request'

/**
 * 查询指定Id用户预约列表
 */
export const getUserReservePage = data => {
  return request({
    url: '/userReserve/page',
    params: data
  })
}

/**
 * 更新预约信息
 */
export const updateUserReserve = data => {
  return request({
    url: '/userReserve',
    method: 'PUT',
    data
  })
}
