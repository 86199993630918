import layout from '@/layout'

export default {
  path: '/product',
  component: layout,
  redirect: '/product/category',
  name: 'productCategory',
  meta: {
    title: 'product',
    icon: 'product'
  },
  children: [
    {
      path: '/product/category',
      component: () =>
        import(
          /* webpackChunkName: "product-category" */ '@/views/product-category/index'
        ),
      meta: {
        title: 'productCategory',
        icon: 'personnel-manage'
      }
    }
  ]
}
