import layout from '@/layout'

export default {
  path: '/order',
  component: layout,
  redirect: '/userReserve/manage',
  name: 'userReserve',
  meta: {
    title: 'order',
    icon: 'order'
  },
  children: [
    {
      path: '/userReserve/manage',
      component: () =>
        import(
          /* webpackChunkName: "user-reserve-manage" */ '@/views/user-reserve-manage/index'
        ),
      meta: {
        title: 'userReserve',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/userReserve/manage/:userId',
      component: () =>
        import(
          /* webpackChunkName: "user-reserve-manage" */ '@/views/user-reserve-manage/index'
        ),
      meta: {
        title: 'userReserveById'
      }
    }
  ]
}
