import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/sys/dictionary',
  name: 'sysDictionary',
  meta: {
    title: 'system',
    icon: 'system'
  },
  children: [
    {
      path: '/sys/dictionary',
      component: () =>
        import(
          /* webpackChunkName: "sys-dictionary" */ '@/views/sys-dictionary/index'
        ),
      meta: {
        title: 'sysDictionary',
        icon: 'personnel-manage'
      }
    }
  ]
}
