import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/sys/loginRecord',
  name: 'sysLoginRecord',
  meta: {
    title: 'system',
    icon: 'system'
  },
  children: [
    {
      path: '/sys/loginRecord',
      component: () =>
        import(
          /* webpackChunkName: "sys-login-record" */ '@/views/sys-login-record/index'
        ),
      meta: {
        title: 'sysLoginRecord',
        icon: 'personnel-info'
      }
    }
  ]
}
