<template>
  <el-dialog
    :model-value="modelValue"
    width="700px"
    title="修改系统用户"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :inline="true" :label-width="100">
      <el-form-item label="登录账号" prop="username">
        <el-input
          v-model="form.username"
          placeholder="请输入登录账号"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="phone">
        <div style="width: 202px!important;">
          <el-select v-model="form.roles" multiple placeholder="请选择角色">
            <el-option
              v-for="item in roles"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="editSysUserClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getSysRoleList } from '@/api/sys-role'
import { getSysUserByUserId, updateSysUser } from '@/api/sys-user'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  userId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'userId', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  roles: []
})

const loading = ref(false)

// 查询所有角色
const roles = ref([])
const getRolesData = async () => {
  loading.value = true
  roles.value = await getSysRoleList()
  loading.value = false
}
getRolesData()

// 查询用户信息
const getSysUserData = async () => {
  loading.value = true
  form.value = await getSysUserByUserId(props.userId)
  const roleIds = []
  for (const role of form.value.roles) {
    roleIds.push(role.roleId)
  }
  form.value.roles = roleIds
  loading.value = false
}
getSysUserData()

// 修改
const editSysUserClick = async () => {
  loading.value = true
  const putData = JSON.parse(JSON.stringify(form.value))
  putData.roles = []
  for (const roleId of form.value.roles) {
    for (const role of roles.value) {
      if (roleId === role.roleId) {
        putData.roles.push(role)
      }
    }
  }
  await updateSysUser(putData)
  ElMessage.success('修改成功')
  loading.value = false
  closed()
}
</script>

<style lang="scss" scoped></style>
