<template>
  <el-dialog
    :model-value='modelValue'
    width='50%'
    title='跟进记录'
    @close='closed'
  >
    <el-tabs v-model='dialogFollowRecordActiveName'>
      <el-tab-pane label='添加跟进记录' name='addFollowRecord'>
        <el-input
          type='textarea'
          placeholder='请输入内容'
          v-model='form.content'
          maxlength='1000'
          show-word-limit
          :rows='5'
        >
        </el-input>
        <el-button type="primary" style='width: 100%;margin-top: 20px;' @click="addFollowRecord" plain>添 加</el-button>
      </el-tab-pane>
      <el-tab-pane label='历史跟进记录' name='followRecords'>
        <el-table
          max-height='450'
          :data='tableDataList'
          border
          style='width: 100%;margin-bottom: 20px;'>

          <el-table-column
            prop='createTime'
            label='添加时间'
            width='160'>
          </el-table-column>
          <el-table-column
            prop='content'
            label='记录内容'
            min-width='120'>
          </el-table-column>
          <el-table-column
            prop='sysUser.nickname'
            label='负责人'
            width='120'>
          </el-table-column>
        </el-table>
        <div style='text-align: center;padding-top: 20px;'>
          <el-pagination
            layout='total, sizes, prev, pager, next, jumper'
            :page-sizes='[5, 10, 20, 30]'
            :page-size='queryForm.limit'
            :current-page='queryForm.page'
            :total='total'
            @size-change='handleSizeChange'
            @current-change='handleCurrentChange'
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getUserFollowRecordPage, addUserFollowRecord } from '@/api/user-follow-record'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const loading = ref(false)

/**
 * 用户跟进记录分页查询
 * @type {ToRef<*[]>}
 */
const queryForm = ref({
  page: 1,
  limit: 5,
  sort: 'createTime',
  order: 'desc',
  userId: props.id,
  sysUserId: ''
})

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getFavoritesList()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getFavoritesList()
}

const total = ref(0)
const tableDataList = ref([])
const getFavoritesList = async () => {
  loading.value = true
  const result = await getUserFollowRecordPage(queryForm.value)
  tableDataList.value = result.list
  total.value = result.total
  loading.value = false
}
getFavoritesList()

/**
 * 跟进记录tab名称
 * @type {Ref<UnwrapRef<string>>}
 */
const dialogFollowRecordActiveName = ref('addFollowRecord')

/**
 * 添加跟进记录
 * @type {Ref<UnwrapRef<string>>}
 */
const form = ref({
  userId: props.id,
  content: ''
})
const addFollowRecord = async () => {
  if (form.value.content.trim().length <= 0) {
    ElMessage.error('跟进记录内容不能为空')
    return
  }
  await addUserFollowRecord(form.value)
  ElMessage.success('添加成功')
  dialogFollowRecordActiveName.value = 'followRecords'
  form.value.content = ''
  getFavoritesList()
}

</script>

<style lang='scss' scoped></style>
